import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de copa sense obrir-se del tot, que pot arribar als 10 cm de diàmetre, amb el marge corbat i un poc granulós. A l’interior de la copa es troba l’himeni que és de color groguenc un poc castany. La superfície externa és pàl·lida, color beig castany clar o del mateix color que l’himeni i moltes vegades es veu furfuràcia, fet que sembla estar coberta de farina. Les espores són el·líptiques, llises, de 18-25 x 10-13 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      